<template>
  <div>
    <!-- フォーマット名 ============================================================ -->
    <div v-if="!isShowConfirm">
      <div class="c-form__reqTxt l-item">：入力必須</div>
      <div class="l-contents l-box">
        <div class="l-box title-wrap">
          <h3 class="c-lead icon">CSVフォーマット</h3>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label required">
            <label>フォーマット名</label>
          </div>
          <div class="c-inputWrap__items">
            <div class="c-input xlg">
              <input
                placeholder="入力してください"
                type="text"
                v-model="csvformatName"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- フォーマット作成 ============================================================ -->
    <div v-if="!isShowConfirm">
      <div class="c-csv_format__wrap">
        <!-- 非選択 -->
        <div class="c-csv_format__unselected">
          <!-- 選択ボタン -->
          <div class="l-flex cols" :class="{disabled : 0 === countItem}">
            <div @click="toggleAllAccordion" class="c-btn secondary mini close_all"><div class="c-btn-icon close_all"></div></div>
            <div @click="updateAllItems(true)" class="c-btn secondary mini">全て選択</div>
            <div @click="updateAllItems(false)" class="c-btn secondary mini">全て選択解除</div>
          </div>

          <!-- 枠 -->
          <div class="c-csv_format">
            <!-- ヘッダ -->
            <div class="c-csv_format__head">
              <div class="c-csv_format__cell b">選択候補</div>
              <div class="c-csv_format__cell">全<span>{{ countItem }}</span>件</div>
            </div>

            <!-- scroll -->
            <div class="c-csv_format__group_list">
              <template v-if="csvCategoryList.length">
                <template v-for="item of csvCategoryList">
                  <!-- グループ -->
                  <div
                    :key="'item-' + item.csvCategoryId"
                    v-if="updateVisibleGroup(item)"
                    class="c-csv_format__group"
                  >
                    <!-- グループ名 -->
                    <div class="c-csv_format__row category">
                      <div class="c-csv_format__cell cb_set">
                        <div @click="toggleGroupAccordion(item)" class="c-btn-icon accordion" :class="item.isGroupAccordion ? 'down' : 'up'"></div>
                        <div class="c-checkbox">
                          <input
                            v-model="item.isGroupChecked"
                            @change="updateGroupCheck(item)"
                            :id="'cb-us_' + item.csvCategoryId"
                            type="checkbox"
                          />
                          <label :for="'cb-us_' + item.csvCategoryId" class="c-checkbox__label">
                            <span class="c-checkbox__box"></span>
                          </label>
                        </div>
                      </div>
                      <div class="c-csv_format__cell full">
                        {{ item.csvCategoryName }}
                      </div>
                    </div>
                    <!-- リスト項目 -->
                    <AccordionTransition>
                      <div
                        v-if="item.isGroupAccordion"
                        class="c-csv_format__row_group"
                      >
                        <template v-if="item.csvItemList.length">
                          <template v-for="node of item.csvItemList">
                            <div
                              v-if="false === node.isSelected"
                              :key="'node-' + node.csvItemId"
                              class="c-csv_format__row item"
                            >
                              <div class="c-csv_format__cell cb_set">
                                <div class="c-checkbox">
                                  <input
                                    v-model="node.isChecked"
                                    @change="updateEachItemCheck(item, node)"
                                    :id="'cb-us_' + item.csvCategoryId + '_' + node.csvItemId"
                                    type="checkbox"
                                  />
                                  <label
                                    :for="'cb-us_' + item.csvCategoryId + '_' + node.csvItemId"
                                    class="c-checkbox__label"
                                  >
                                    <span class="c-checkbox__box"></span>
                                  </label>
                                </div>
                              </div>
                              <div class="c-csv_format__cell full">
                                {{ node.csvItemName }}
                              </div>
                            </div>
                          </template>
                        </template>
                      </div>
                    </AccordionTransition>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>

        <!-- 移動ボタン -->
        <div class="c-csv_format__switcher">
          <div
            :class="{ disabled: 0 === countCheckedItem }"
            @click="moveCheckedItems"
            class="c-btn primary sm"
          >
            <div class="c-icon add_item"></div>
          </div>
          <div
            :class="{ disabled: 0 === countCheckedSelectedItem }"
            @click="moveCheckedSelectedItems"
            class="c-btn secondary sm"
          >
            <div class="c-icon remove_item"></div>
          </div>
        </div>

        <!-- 非選択 -->
        <div class="c-csv_format__selected">
          <!-- 選択ボタン -->
          <div class="l-flex cols" :class="{disabled : 0 === countSelectedItem}">
            <div
              @click="updateAllSelectedItems(true)"
              class="c-btn secondary mini"
            >
              全て選択
            </div>
            <div
              @click="updateAllSelectedItems(false)"
              class="c-btn secondary mini"
            >
              全て選択解除
            </div>
          </div>

          <!-- 枠 -->
          <div class="c-csv_format">
            <!-- ヘッダ -->
            <div class="c-csv_format__head">
              <div class="c-csv_format__cell b">選択済み項目</div>
              <div class="c-csv_format__cell">全<span>{{ countSelectedItem }}</span>件</div>
            </div>

            <!-- scroll -->
            <div class="c-csv_format__group_list">
              <template v-if="csvSelectedItemList.length">
                <draggable v-model="csvSelectedItemList" handle=".draggable">
                  <template v-for="item of csvSelectedItemList">
                    <div
                      :key="'selectedItem-' + item.csvItemId"
                      class="c-csv_format__row item"
                    >
                      <div class="c-csv_format__cell cb_set">
                        <div class="c-btn-icon draggable"></div>
                        <div class="c-checkbox">
                          <input
                            v-model="item.isChecked"
                            type="checkbox"
                            name=""
                            :id="'sd_' + item.csvItemId"
                          />
                          <label :for="'sd_' + item.csvItemId" class="c-checkbox__label">
                            <span class="c-checkbox__box"></span>
                          </label>
                        </div>
                      </div>
                      <div class="c-csv_format__cell full">
                        {{ item.csvCategoryName + "_" + item.csvItemName }}
                      </div>
                    </div>
                  </template>
                </draggable>
              </template>
            </div>
          </div>

          <!-- ソート -->
          <div class="c-csv_format__sorter">
            <div
              :class="{ disabled: 0 === countCheckedSelectedItem || csvSelectedItemList.length === countCheckedSelectedItem }"
              @click="orderSelectedItems('begin')"
              class="c-btn secondary sm"
            >
              <div class="c-icon begin"></div>最初へ
            </div>

            <div
              :class="{ disabled: 0 === countCheckedSelectedItem || csvSelectedItemList.length === countCheckedSelectedItem }"
              @click="orderSelectedItems('up')"
              class="c-btn secondary sm"
            >
              <div class="c-icon up"></div>上へ
            </div>

            <div
              :class="{ disabled: 0 === countCheckedSelectedItem || csvSelectedItemList.length === countCheckedSelectedItem }"
              @click="orderSelectedItems('down')"
              class="c-btn secondary sm"
            >
              <div class="c-icon down"></div>下へ
            </div>

            <div
              :class="{ disabled: 0 === countCheckedSelectedItem || csvSelectedItemList.length === countCheckedSelectedItem }"
              @click="orderSelectedItems('end')"
              class="c-btn secondary sm"
            >
              <div class="c-icon end"></div>最後へ
            </div>
          </div>
        </div>
      </div>

      <!-- ボタン ============================================================ -->
      <div v-if="!isShowConfirm">
        <div id="float-btns" class="l-btns l-container">
          <div class="left">
            <div @click="backHandler" class="c-btn secondary small back">戻る</div>
          </div>
          <div :class="{ disabled: !csvSelectedItemList.length || 0 === csvformatName.length }" class="center">
            <div @click="updateConfirm(true)" class="c-btn primary mid">確認</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 確認 ============================================================ -->
    <div v-if="isShowConfirm">
      <div class="l-contents l-box">
        <div class="l-box title-wrap">
          <h3 class="c-lead icon">CSVフォーマット</h3>
        </div>
        <div class="c-csv_format__confirm">
          <div class="c-caption">フォーマット名</div>
          <div class="c-desc">{{ csvformatName }}</div>
          <div class="c-caption">出力項目（全{{ csvSelectedItemList.length }}項目）</div>
          <div class="c-desc">
            <template v-if="csvSelectedItemList.length">
              <template v-for="item of csvSelectedItemList">
                <div :key="'confirm-' + item.csvItemId" class="c-csv_format__confirm__item">{{ item.csvCategoryName + "_" + item.csvItemName }}</div>
              </template>
            </template>
          </div>
        </div>
      </div>

      <!-- ボタン ============================================================ -->
      <div>
        <div class="l-btns l-container">
          <div class="left"><div @click="updateConfirm(false)" class="c-btn secondary small back">戻る</div></div>
          <div class="center"><div @click="resistCSVformat" class="c-btn primary mid" :class="{disabled: isLock}">登録</div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.c-csv_format {
  height: 560px;
  margin-top: 14px;
  border: 1px solid #dce7fa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: white;

  &__wrap {
    display: flex;
    align-items: stretch;
    margin-bottom: 30px;
  }
  &__unselected {
    width: 350px;


  }
  &__selected {
    flex: 1;
    padding-right: 100px;
    position: relative;
  }
  &__head {
    height: 26px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccd0d7;
    background: white;
    padding: 0 10px;
    font-size: 12px;
    color: #334766;

    .b {
      font-weight: bold;
    }

    .c-csv_format__cell span {
      padding: 0 0.8rem;
    }
  }
  &__group_list {
    overflow-y: auto;
    position: relative;

    &:empty {
      &::before {
        content: "項目を追加してください。";
        padding: 14px 10px;
        font-size: 14px;
        display: block;
      }
    }
  }
  &__group {
    border-bottom: 2px solid #ccd0d7;
    &:last-child {
      border-bottom: none;
    }
  }
  &__row {
    padding: 12px 10px;
    border-bottom: 1px solid #ccd0d7;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }
  }
  &__cell {
    display: flex;
    &.cb_set {
      width: 58px;
      margin-right: 8px;
      .c-checkbox {
        margin-left: auto;
      }
      .c-checkbox__box {
        margin-right: 0;
      }
    }
    &.full {
      flex: 1;
      font-size: 14px;
      line-height: 1.28571429;
    }
  }
  &__switcher {
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px;
    flex-shrink: 0;

    .c-btn {
      + .c-btn {
        margin-top: 40px;
      }

      width: 80px;
    }
  }
  &__sorter {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 230px;
    position: absolute;
    background: white;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 1px solid #dce7fa;
    border-left: none;
    border-radius: 0 8px 8px 0;

    .c-btn {
      + .c-btn {
        margin-top: 20px;
      }

      width: 80px;
      padding-right: 0.8rem;
      padding-left: 0.8rem;
      text-align: left;

      .c-icon {
        margin-right: 8px;
      }
    }
  }
  &__confirm {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    font-size: 1.4rem;
    line-height: 1.71428571;
    margin-bottom: -14px;
    color: #334D66;
    margin-left: 28px;
    .c-caption {
      width: 220px;
      margin-bottom: 14px;
    }
    .c-desc {
      width: calc(100% - 240px);
      margin-left: auto;
      margin-bottom: 14px;
    }
    &__item {
      + .c-csv_format__confirm__item {
        margin-top: 14px;
      }
    }
  }
}

.c-btn.close_all {
  min-width: 3.6rem;
  min-height: 3.6rem;
  background: {
    image: url('/v_resources/assets/img/icon/closeAll_blue.svg');
    repeat: no-repeat;
    position: center;
  }
  &:hover {
    background: {
      image: url('/v_resources/assets/img/icon/closeAll_white.svg');
      repeat: no-repeat;
      position: center;
    }
  }
}

.c-btn-icon {
  &.accordion {
    width: 24px;
    height: 24px;
    position: relative;
    background: #0B5AD9;;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      background: {
        image: url('/v_resources/assets/img/icon/arrow_white.svg');
        repeat: no-repeat;
        position: center;
      }
    }

    &.up::before {
      transform: rotate(180deg);
    }
  }
  &.draggable {
    width: 24px;
    height: 24px;
    background: {
      image: url('/v_resources/assets/img/icon/sortaction_blue.svg');
      repeat: no-repeat;
      position: center;
    }
  }
}
.c-icon {
  &.add_item {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: {
      image: url('/v_resources/assets/img/icon/arrow_r_white.svg');
      repeat: no-repeat;
      position: center;
    }
  }
  &.remove_item {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: {
      image: url('/v_resources/assets/img/icon/arrow_l_blue.svg');
      repeat: no-repeat;
      position: center;
    }
    .c-btn:hover & {
      background: {
        image: url('/v_resources/assets/img/icon/arrow_l_white.svg');
        repeat: no-repeat;
        position: center;
      }
    }
  }

  &.top {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: {
      image: url('/v_resources/assets/img/icon/arrow_up_blue.svg');
      repeat: no-repeat;
      position: center;
    }
    .c-btn:hover & {
      background: {
        image: url('/v_resources/assets/img/icon/arrow_up_white.svg');
        repeat: no-repeat;
        position: center;
      }
    }
  }

  &.begin {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: {
      image: url('/v_resources/assets/img/icon/arrow_begin_blue.svg');
      repeat: no-repeat;
      position: center;
    }
    .c-btn:hover & {
      background: {
        image: url('/v_resources/assets/img/icon/arrow_begin_white.svg');
        repeat: no-repeat;
        position: center;
      }
    }
  }

  &.up {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: {
      image: url('/v_resources/assets/img/icon/arrow_up_blue.svg');
      repeat: no-repeat;
      position: center;
    }
    .c-btn:hover & {
      background: {
        image: url('/v_resources/assets/img/icon/arrow_up_white.svg');
        repeat: no-repeat;
        position: center;
      }
    }
  }

  &.down {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: {
      image: url('/v_resources/assets/img/icon/arrow_down_blue.svg');
      repeat: no-repeat;
      position: center;
    }
    .c-btn:hover & {
      background: {
        image: url('/v_resources/assets/img/icon/arrow_down_white.svg');
        repeat: no-repeat;
        position: center;
      }
    }
  }

  &.end {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: {
      image: url('/v_resources/assets/img/icon/arrow_end_blue.svg');
      repeat: no-repeat;
      position: center;
    }
    .c-btn:hover & {
      background: {
        image: url('/v_resources/assets/img/icon/arrow_end_white.svg');
        repeat: no-repeat;
        position: center;
      }
    }
  }
}
</style>

<script>
// import { API_URL, DOMAIN } from "@/const";
import draggable from "vuedraggable";
import callApi from "../mixins/callApi";
import AccordionTransition from "../components/AccordionTransition";

export default {
  components: {
    draggable,
    AccordionTransition,
  },
  mixins: [callApi],
  props: {
    MODE: {
      type: String,
      required: true,
    },
    errMsgs: {
      type: Array,
    },
  },
  data() {
    return {
      /**
       * 選択候補データ
       */
      csvCategoryList: [],
      /**
       * 選択済み項目データ
       */
      memberId: this.$store.getters["userInfo/get"].id,
      csvformatId: 0,
      csvformatName: "",
      csvSelectedItemList: [],
      /**
       * 選択候補：アコーディオンの開閉状態
       */
      isOpenAllAccordion: false,
      /**
       * 確認画面表示状態
       */
      isShowConfirm: false,
      /**
       * 選択候補：項目数
       */
      countItem: 0,
      /**
       * 選択済み項目：項目数
       */
      countSelectedItem: 0,
      /**
       * 選択候補：チェックの入っている項目数
       */
      countCheckedItem: 0,
      /**
       * 選択済み項目：チェックの入っている項目数
       */
      countCheckedSelectedItem: 0,
      /**
       * 登録処理中
       */
      isLock: false,
    };
  },
  methods: {
    /**
     * 選択候補：全グループアコーディオンの開閉トグル
     */
    toggleAllAccordion() {
      let count = this.csvCategoryList.length;
      let flag = !(
        this.csvCategoryList.filter((m) => {
          if (false === this.updateVisibleGroup(m)) {
            --count;
            return false;
          }
          return m.isGroupAccordion;
        }).length === count
      );
      this.isOpenAllAccordion = flag;
      this.csvCategoryList.map((m) => {
        m.isGroupAccordion = flag;
      });
    },
    /**
     * 選択候補：グループアコーディオンの表示・非表示
     * @param {object} item
     * @return {*|boolean}
     */
    updateVisibleGroup(item) {
      return item.csvItemList && (item.csvItemList.length > item.csvItemList.filter((m) => m.isSelected).length)
    },
    /**
     * 選択候補：全て選択 / 全て選択解除
     * @param {boolean} flag
     */
    updateAllItems(flag) {
      this.csvCategoryList.map((m) => {
        m.isGroupChecked = flag;
        this.updateGroupCheck(m);
      });
    },
    /**
     * 選択候補：アコーディオン開閉トグル
     * @param {object} item
     */
    toggleGroupAccordion(item) {
      item.isGroupAccordion = !item.isGroupAccordion;
    },
    /**
     * 選択済み項目：全て選択 / すべて選択解除
     * @param {boolean} flag
     */
    updateAllSelectedItems(flag) {
      this.csvSelectedItemList.map((m) => {
        m.isChecked = flag;
      });
    },
    /**
     * 選択候補：グループ選択状態を内部項目に反映
     * @param {object} item
     */
    updateGroupCheck(item) {
      // item.isGroupChecked = !item.isGroupChecked; // v-model
      item.csvItemList &&
        item.csvItemList.map((m) => {
          m.isChecked = item.isGroupChecked;
        });
    },
    /**
     * 選択候補：グループ内の項目選択状態をグループ選択状態に反映
     * @param {object} item
     * @param {object} node
     */
    updateEachItemCheck(item, node) {
      if (false === node.isChecked) {
        item.isGroupChecked = false;
      } else if (item.csvItemList) {
        if (item.csvItemList.filter((m) => m.isSelected || m.isChecked).length === item.csvItemList.length) {
          item.isGroupChecked = true;
        }
      }
    },
    /**
     * 選択候補->選択済み項目
     */
    moveCheckedItems() {
      // 左リストで選択された項目を右リストに移動させる
      this.csvCategoryList.map((m) => {
        m.csvItemList &&
          m.csvItemList
            .filter((m) => !m.isSelected && m.isChecked)
            .map((n, index) => {
              n.isSelected = true;
              this.csvSelectedItemList.push({
                csvCategoryId: m.csvCategoryId,
                csvCategoryName: m.csvCategoryName,
                csvItemId: n.csvItemId,
                csvItemName: n.csvItemName,
                displayOrder: index,
                isChecked: false,
              });
            });
      });
    },
    /**
     * 選択済み項目->選択候補
     */
    moveCheckedSelectedItems() {
      let deleteArr = [];
      this.csvSelectedItemList.map((m, index) => {
        if (!m.isChecked) {
          return false;
        }
        this.csvCategoryList
          .filter((n) => n.csvCategoryId === m.csvCategoryId)
          .map((n) => {
            n.csvItemList &&
              n.csvItemList
                .filter((o) => o.csvItemId === m.csvItemId)
                .map((o) => {
                  n.isGroupChecked = false;
                  o.isChecked = false;
                  o.isSelected = false;
                  deleteArr.unshift(index);
                });
          });
      });
      deleteArr.map((m) => {
        this.csvSelectedItemList.splice(m, 1);
      });
    },
    /**
     * 選択済み項目：最初へ 上へ 下へ 最後へ
     * @param {string} command
     */
    orderSelectedItems(command) {
      if (!this.csvSelectedItemList) {
        return;
      }
      let arr = this.csvSelectedItemList;
      switch (command) {
        case "begin":
          arr = arr
            .filter((m) => m.isChecked)
            .concat(arr.filter((m) => !m.isChecked));
          break;
        case "up":
          arr.map((m, index) => {
            if (index === 0 || !m.isChecked || arr[index - 1].isChecked) {
              return;
            }
            arr.splice(index, 1);
            arr.splice(index - 1, 0, m);
          });
          break;
        case "down":
          arr = arr.reverse();
          arr.map((m, index) => {
            if (index === 0 || !m.isChecked || arr[index - 1].isChecked) {
              return;
            }
            arr.splice(index, 1);
            arr.splice(index - 1, 0, m);
          });
          arr = arr.reverse();
          break;
        case "end":
          arr = arr
            .filter((m) => !m.isChecked)
            .concat(arr.filter((m) => m.isChecked));
          break;
      }
      this.csvSelectedItemList = arr;
    },
    /**
     * 選択済み項目：選択済み項目データ csvSelectedItemList の displayOrder を更新
     * ・発火タイミングをwatch下にしてあるが、登録API直前に行うだけでも大丈夫なはず
     */
    updateSelectedItemOrder() {
      this.csvSelectedItemList.map((m, index) => (m.displayOrder = index));
    },
    /**
     * 選択済み項目のロード後、選択候補の表示状態の同期をとる
     */
    syncSelectionState() {
      this.csvSelectedItemList.map((m) => {
        this.csvCategoryList
          .filter((n) => n.csvCategoryId === m.csvCategoryId)
          .map((n) => {
            n.csvItemList &&
              n.csvItemList
                .filter((o) => o.csvItemId === m.csvItemId)
                .map((o) => {
                  o.isChecked = false;
                  o.isSelected = true;
                });
          });
      });
    },
    /**
     * 複製モードのみ、フォーマット名に「のコピー」を追加する
     */
    duplicatedFormatName() {
      if ("duplicate" === this.MODE) {
        this.csvformatName += " のコピー";
      }
    },
    /**
     * 戻る押下（遷移元に戻る）
     */
    backHandler() {
      this.$router.go(-1);
    },
    /**
     * 確認画面の表示状態
     * @param {boolean} flag
     */
    updateConfirm(flag) {
      this.isShowConfirm = flag;
    },
    /**
     * CSVフォーマット登録処理
     */
    resistCSVformat() {
      this.errMsgs.splice(0);
      this.isLock = true;
      switch (this.MODE) {
        case "input":
        case "duplicate":
          this.postManifestCSVformatRegistrationApi(this.generateRegistrationData())
          .finally(() => { this.isLock = false; });
          break;
        case "edit":
          this.putManifestCSVFormatEditApi(this.generateEditedData())
          .finally(() => { this.isLock = false; });
          break;
      }
    },
    /**
     * CSVフォーマット登録API 送信データ生成
     * POST /manifest/csvformat
     * @return {{csvformatCreateList: [{csvformatName: string, csvItemCreateList: {csvItemId: number, displayOrder: number}[], memberId: number}]}}
     */
    generateRegistrationData() {
      this.updateSelectedItemOrder();
      return {
        csvformatCreateList: [
          {
            memberId: this.memberId,
            csvformatName: this.csvformatName,
            csvItemCreateList: this.csvSelectedItemList.map((m) => {
              return {
                csvItemId: m.csvItemId,
                displayOrder: m.displayOrder,
              };
            })
          }
        ]
      };
    },
    /**
     * CSVフォーマット編集API 送信データ生成
     * PUT /manifest/csvformat/{id}
     * @return {{csvformatName: string, updateMemberId: default.$store.getters.userInfo/get.id, csvItemEditList: {csvItemId: *, displayOrder: *}[]}}
     */
    generateEditedData() {
      this.updateSelectedItemOrder();
      return {
        updateMemberId: this.memberId,
        csvformatName: this.csvformatName,
        csvItemEditList: this.csvSelectedItemList.map((m) => {
          return {
            csvItemId: m.csvItemId,
            displayOrder: m.displayOrder,
          };
        })
      };
    },
  },

  watch: {
    csvCategoryList: {
      /**
       * 選択候補：
       * 項目総数の更新
       * チェックした項目数の更新
       */
      handler: function () {
        let _countItem = 0, _countCheckedItem = 0;
        this.csvCategoryList.map((list) => {
          if (list.csvItemList) {
            _countItem += list.csvItemList.filter((m) => false === m.isSelected).length;
            _countCheckedItem += list.csvItemList.filter((m) => false !== !m.isSelected && m.isChecked).length;
          }
        });
        this.countItem = _countItem;
        this.countCheckedItem = _countCheckedItem;
      },
      deep: true,
    },
    csvSelectedItemList: {
      /**
       * 選択済み項目：
       * 項目総数の更新
       * チェックした項目数の更新
       */
      handler: function () {
        this.countSelectedItem = this.csvSelectedItemList.length;
        this.countCheckedSelectedItem = this.csvSelectedItemList.filter((m) => m.isChecked).length;
        // this.updateSelectedItemOrder();
      },
      deep: true,
    },
  },

  beforeCreate() {},
  created() {
    /**
     * 選択候補の一覧を取得
     */
    this.getManifestCSVFormatItemsApi().then(() => {
      // 選択済み項目の取得
      switch (this.MODE) {
        case "edit":
          if (undefined !== this.$route.params.id) {
            this.getManifestCSVFormatDetailListApi(this.$route.params.id)
            .then(this.syncSelectionState);
          }
          break;
        case "duplicate":
          if (undefined !== this.$route.params.csvformatid) {
            this.getManifestCSVFormatDetailListApi(this.$route.params.csvformatid)
            .then(() => {
              this.syncSelectionState();
              this.duplicatedFormatName();
            });
          }
          break;
      }
    });
  },
  beforeMount() {},
  mounted() {},
};
</script>
