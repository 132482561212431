import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      collector: {},
    };
  },

  methods: {
    /**
     * 対象項目取得API
     * /manifest/csvformat/items
     * @return {Promise<AxiosResponse<any>>}
     */
    getManifestCSVFormatItemsApi() {
      return axios
        .get(API_URL.MANIFEST.CSV_FORMAT_ITEMS)
        .then((res) => {
          this.csvCategoryList = res.data.csvCategoryList;
        })
        .then(() => {
          this.csvCategoryList.map((list) => {
            this.$set(list, "isGroupChecked", false);
            this.$set(list, "isGroupAccordion", false);
            // this.$set(list, "isGroupAccordion", true);
            list.csvItemList && list.csvItemList.map((m) => {
                this.$set(m, "isChecked", false);
                this.$set(m, "isSelected", false);
              });
          });
        })
        .catch((err) => {
          console.log(err);
          this.csvCategoryList.splice(0);
        });
    },
    /**
     * CSVフォーマット情報取得API
     * /manifest/csvformat/{id}
     * @param {number} id
     * @return {Promise<AxiosResponse<any>>}
     */
    getManifestCSVFormatDetailListApi(csvformatId) {
      return axios
        .get(`${API_URL.MANIFEST.CSV_FORMAT}/${csvformatId}`)
        .then((res) => {
          var csvformatDetailList = res.data.csvformatDetailList;
          if(csvformatDetailList){
            this.memberId = csvformatDetailList.memberId;
            this.csvformatId = csvformatDetailList.csvformatId;
            this.csvformatName = csvformatDetailList.csvformatName;
            this.csvSelectedItemList = csvformatDetailList.csvTargetItemList;
          }
        })
        .then(() => {
          this.csvSelectedItemList.map((list) => {
            this.$set(list, "isChecked", false);
          });
        })
        .catch((err) => {
          console.log(err);
          this.memberId = 0;
          this.csvformatId = 0;
          this.csvformatName = "";
          this.csvSelectedItemList.splice(0);
        });
    },
    /**
     * CSVフォーマット登録API
     * /manifest/csvformat
     * @return {Promise<AxiosResponse<any>>}
     */
    postManifestCSVformatRegistrationApi(data) {
      return axios
        .post(API_URL.MANIFEST.CSV_FORMAT, data)
        .then(() => {
          this.$route.query.from ? this.$router.push(decodeURIComponent(this.$route.query.from)) : this.$router.push("/manifests/all");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    /**
     * CSVフォーマット編集API
     * /manifest/csvformat/{id}
     * @return {Promise<AxiosResponse<any>>}
     */
    putManifestCSVFormatEditApi(data) {
      return axios
        .put(`${API_URL.MANIFEST.CSV_FORMAT}/${this.$route.params.id}`, data)
        .then(() => {
          this.$route.query.from ? this.$router.push(decodeURIComponent(this.$route.query.from)) : this.$router.push("/manifests/all");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
  },
};
