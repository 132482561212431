<template>
  <transition
    name="accordion"
    @before-enter="accordion_beforeEnter"
    @enter="accordion_enter"
    @after-enter="accordion_afterEnter"
    @before-leave="accordion_beforeLeave"
    @after-leave="accordion_afterLeave"
  >
    <slot />
  </transition>
</template>

<style lang="scss" scoped>
* {
  transition: height 0.12s ease-in-out;
  overflow: hidden;
}
</style>

<script>
export default {
  methods: {
    accordion_beforeEnter: function(el) { el.style.height = '0'; },
    accordion_enter: function(el) { el.style.height = el.scrollHeight + 'px'; },
    accordion_afterEnter: function(el) { el.style.height = 'auto'; },
    accordion_beforeLeave: function(el) { el.style.height = el.scrollHeight + 'px'; window.requestAnimationFrame(() => { el.style.height = "0"; }); },
    accordion_afterLeave: function(el) { el.style.height = '0'; },
  },
};
</script>
